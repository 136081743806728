import React from 'react';

import { PageProps, Link, graphql } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import './index.scss'
import MercadoLibreLogo from "../../../components/images/mercadolibre"
import SectionNavigate from '../../../components/widgets/sectionNavigate';
import ImageFooted from '../../../components/widgets/imageFooted';
import { IMG_Shopify } from '../../../components/images/docs/shopify';
import { IMG_Mercadolibre } from '../../../components/images/docs/mercadolibre';
import AmazonLogo from '../../../components/images/amazon';

type DataProps = {
    site: {
        buildTime: string
    }
}

const ShopifyClavesDefault: React.FC<PageProps<DataProps>> = ({ data, path, location }) => (
    <Layout isEcommerce={true} location={location}>
        <SEO title="FiscalPOP para eCommerce" />
        <div id='ShopifyDocs' className="_DOCS">
            <div className="integrationLogo">
                <AmazonLogo />
            </div>
            <h2>
                Facturación global
            </h2>
            <p>
                Desde la vista de "Mis pedidos" encontrarás la opción de <b>Ir a Factura Global</b>.
            </p>
            <ImageFooted explanation={`Todos los pedidos que no reclamaron factura podrán ser emitidos en una sola factura global. Aquí podrás cambiar el mes a facturar, por default inicia a partir de la última factura global emitida, pero puedes cambiar al mes completo o el mes anterior.`}>
                <IMG_Mercadolibre file={`facturacionGlobal`} />
            </ImageFooted>
            <p>
                <b>Cuando un cliente solicita facturar un pedido que se encuentra en una factura global, </b> primero se emite una nota de crédito contra la factura global por el valor de la factura del cliente, posteriormente el cliente recibirá la factura normal.
            </p>
            <p>
                Por ley, los compradores tienen de 2 a 3 días para obtener su factura, por lo que clientes que obtengan el link de facturación los últimos dos dias del mes, tendrán hasta el 2 del mes siguiente para obtener su factura.
            </p>
            <h3>
                Facturas globales automáticas
            </h3>
            <p>
                El proceso automático busca reducir el tiempo que tengas que invertir para ordenar tus facturas a fin de mes.<br />
                Se generará una factura a mostrador con las ventas pagadas que no hayan sido facturadas al igual que emitirla manualmente, solamente que no requiere tu atención.
            </p>
            <p>
                <b>Este proceso corre a las 12:02AM del primer dia de cada mes (e.g. 1ero de Septiembre 12:02AM), pero la factura se genera con fecha de las 11:30PM del último dia (e.g. 31 de Agosto 11:30PM)</b>
            </p>
            <ImageFooted explanation={`En "Mi configuración" podrás activar la facturación global automática y definir el periodo semanal o mensual.`}>
                <IMG_Mercadolibre file={`configGlobalAuto`} />
            </ImageFooted>
            <SectionNavigate next={`/ecommerce/amazon/productos`} previous={`/ecommerce/amazon/facturacion-reembolso`} />
        </div>
    </Layout>
)

export default ShopifyClavesDefault;